<template>
  <b-modal
    id="assign-booking-modal"
    :title="$t('reservation.assignBooking.title')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    no-enforce-focus
    hide-footer
    size="md"
    @show="handleShowModal"
  >
    <validation-observer
      ref="refFormObserver"
      #default="{ invalid, pristine }"
    >
      <div>
        <h5 class="mt-50">
          {{ $t('reservation.chooseAgencyOrCustomerTitle') }}:
        </h5>
        <b-row>
          <!-- ANCHOR Select Agency -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('reservation.agency')"
              rules=""
            >
              <b-form-group>
                <template #label>
                  <div>
                    {{ $t('reservation.agency') }}
                    <!-- <span class="text-danger">(*)</span> -->
                  </div>
                </template>
                <v-select
                  id="agency-to-assign"
                  v-model="agencyToAssign"
                  class="w-100"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :reduce="val => val"
                  :options="agencyToAssignOptions"
                  label="agencyCode"
                  :disabled="!isRoleF1"
                  :filterable="false"
                  :loading="loadingAgencies"
                  :placeholder="$t('reservation.placeholderSelect')"
                  @open="handleOpenAgency"
                  @search="handleSearchAgency"
                  @input="handleInputAgency"
                >
                  <template #selected-option="{ agencyName, agencyCode }">
                    <div>
                      <span class="d-block font-weight-bold text-truncate">
                        {{ agencyName }} <small>({{ agencyCode }})</small>
                      </span>
                    </div>
                  </template>
                  <template v-slot:option="{ agencyName, agencyCode }">
                    <div>
                      <span class="d-block font-weight-bold text-truncate">
                        {{ agencyName }} <small>({{ agencyCode }})</small>
                      </span>
                    </div>
                  </template>
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- ANCHOR select Customer -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('reservation.customer')"
              rules=""
            >
              <b-form-group>
                <template #label>
                  <div>
                    {{ $t('reservation.customer') }}
                    <!-- <span class="text-danger">(*)</span> -->
                  </div>
                </template>
                <v-select
                  v-model="customerToAssign"
                  class="w-100"
                  :state="getValidationState(validationContext) === false ? false : null"
                  :reduce="val => val"
                  label="code"
                  :filterable="false"
                  :options="customerToAssignOptions"
                  :disabled="!isRoleF1"
                  :loading="loadingCustomers"
                  :placeholder="$t('reservation.placeholderSelect')"
                  @open="handleOpenCustomer"
                  @search="handleSearchCustomer"
                  @input="handleInputCustomer"
                >
                  <template #selected-option="{ lastName, firstName, code }">
                    <div>
                      <span class="d-block font-weight-bold text-truncate">
                        {{ `${lastName} ${firstName}` }} <small>({{ code }})</small>
                      </span>
                    </div>
                  </template>
                  <template v-slot:option="{ lastName, firstName, code }">
                    <div>
                      <span class="d-block font-weight-bold text-truncate">
                        {{ `${lastName} ${firstName}` }} <small>({{ code }})</small>
                      </span>
                    </div>
                  </template>
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    />
                  </template>
                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
      <div class="mt-1 d-flex-center gap-2">
        <b-button
          variant="gradient"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || pristine"
          @click="confirmFindPnrCode"
        >
          {{ $t('reservation.assignBooking.title') }}
        </b-button>
      </div>
    </validation-observer>
    <!-- <template #modal-footer="{ close }">
      <div
        v-if="alreadyAutoIssueTime"
        class="flex-1"
      >
        <b-button
          variant="outline-danger"
          pill
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="handleDelete"
        >
          Huỷ đặt giờ xuất vé
        </b-button>
      </div>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        pill
        class="btn-gradient mt-lg-25  border-0"
        :disabled="(!dataToEdit.autoIssueTime) || (!indexSelectedFlightToRecalculatePrice.length && shouldRecalculatePrice) || (resCheckCredit && (resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent))"
        @click="submitRecalculatePrice"
      >
        <span class="align-middle">{{ $t('reservation.save') }}</span>
      </b-button>
    </template> -->
  </b-modal>
</template>

<script>
import {
  BButton, BFormInvalidFeedback, BFormGroup, BRow, BCol, BModal,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  computed, ref, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { debounce } from 'lodash'

import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'

import useReservationHandle from '@reservation/useReservationHandle'

import reservationStoreModule from '../reservationStoreModule'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  setup(props, { root }) {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })
    const { refFormObserver, getValidationState } = formValidation()
    const pnrCodeData = ref(props.bookingData.bookingCode)
    const customerToAssign = ref()
    const agencyToAssign = ref()
    const agencyToAssignOptions = ref([])
    const agencyOptionsDefault = ref([])
    const customerToAssignOptions = ref([])
    const customerOptionsDefault = ref([])
    const {
      fetchAgencies, fetchCustomers, assignBooking,
      loadingAgencies,
      loadingCustomers,
    } = useReservationHandle()

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meData = computed(() => store.getters['userStore/getMeData'])

    function confirmFindPnrCode() {
      const assignString = agencyToAssign.value
        ? `${this.$t('Agency')} ${agencyToAssign.value?.agencyCode} (${agencyToAssign.value.agencyName})`
        : customerToAssign.value
          ? `${this.$t('customers')} ${customerToAssign.value?.code} (${customerToAssign.value.lastName} ${customerToAssign.value.firstName})`
          : `chính mình (${meData.value?.username || `${meData.value?.lastName} ${meData.value?.firstName}`})`
      const titleMsg = () => this.$createElement('div', {
        domProps: {
          innerHTML: `
            <span class="font-weight-bold text-16px">Anh/chị chắc chắn muốn đồng bộ code <code class="code-agency">${pnrCodeData.value}</code> cho <span class="font-weight-bolder text-danger">${assignString}</span>?</span>
          `,
        },
      })
      root.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'md',
          okVariant: 'info',
          okTitle: this.$t('modal.iamConfirm'),
          cancelTitle: this.$t('modal.close'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payload = {
              agencyId: agencyToAssign.value?.id || undefined,
              customerId: customerToAssign.value?.id || undefined,
            }
            assignBooking(props.bookingData.id, payload)
              .then(() => {
                customerToAssign.value = null
                agencyToAssign.value = null
                root.$emit('bv::hide::modal', 'assign-booking-modal')
              })
          }
        })
    }

    function handleOpenAgency() {
      if (agencyOptionsDefault.value && agencyOptionsDefault.value.length === 0) {
        fetchAgencies()
          .then(res => {
            agencyToAssignOptions.value = res
            agencyOptionsDefault.value = res
          })
      } else {
        agencyToAssignOptions.value = agencyOptionsDefault.value
      }
    }

    const handleSearchAgency = debounce(search => {
      if (search) {
        fetchAgencies(search).then(res => {
          agencyToAssignOptions.value = res
        })
      }
    }, 300)

    function handleInputAgency() {
      customerToAssign.value = null
    }

    function handleOpenCustomer() {
      if (customerOptionsDefault.value && customerOptionsDefault.value.length === 0) {
        fetchCustomers()
          .then(res => {
            customerToAssignOptions.value = res
            customerOptionsDefault.value = res
          })
      } else {
        customerToAssignOptions.value = customerOptionsDefault.value
      }
    }

    const handleSearchCustomer = debounce(search => {
      if (search) {
        fetchCustomers(search).then(res => {
          customerToAssignOptions.value = res
        })
      }
    }, 300)

    function handleInputCustomer() {
      agencyToAssign.value = null
    }

    function handleShowModal() {
      customerToAssign.value = null
      agencyToAssign.value = null
    }

    return {
      refFormObserver,
      getValidationState,
      confirmFindPnrCode,

      isRoleF1,

      // agency
      agencyToAssign,
      agencyToAssignOptions,
      handleOpenAgency,
      handleSearchAgency,
      handleInputAgency,

      // customer
      customerToAssign,
      customerToAssignOptions,
      handleOpenCustomer,
      handleSearchCustomer,
      handleInputCustomer,

      loadingAgencies,
      loadingCustomers,
      handleShowModal,
    }
  },
}
</script>
